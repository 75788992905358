<template>
  <div ref="chart" class="chart"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import chartLocaleMixin from "../../../../mixins/chart-locale-mixin";

export default {
  name: "ConsumersTab",
  mixins: [chartLocaleMixin],
  props: {
    values: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      /** @type {am5.Root} */
      root: null,
      /** @type {am5xy.ColumnSeries} */
      newCustomersSeries: null,
      /** @type {am5xy.ColumnSeries} */
      customersReturningSeries: null,
    };
  },
  watch: {
    currentLocale(old, value) {
      if (old !== value) {
        this.root.dispose();
        this.draw();
      }
    },
    values() {
      this.setData();
    },
  },
  methods: {
    setData() {
      this.newCustomersSeries.data.setAll(this.values);
      this.customersReturningSeries.data.setAll(this.values);
    },
    draw() {
      this.root = am5.Root.new(this.$refs.chart);
      this.root._logo?.dispose();

      this.root.setThemes([am5themes_Animated.new(this.root)]);

      const chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          paddingLeft: 0,
          paddingRight: 0,
        })
      );

      chart.get("colors").set("step", 3);

      const cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
      cursor.lineY.set("visible", false);

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(this.root, {
          maxDeviation: 0.3,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(this.root, {
            minorGridEnabled: true,
          }),
          tooltip: am5.Tooltip.new(this.root, {}),
        })
      );

      const xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);
      xRenderer.labels.template.setAll({
        fill: am5.color(0x6e6e82),
        fontSize: 12,
        paddingTop: 12.5,
        paddingBottom: 24,
      });

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          maxDeviation: 0.3,
          maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(this.root, {
            opposite: true,
          }),
        })
      );

      const yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.set("fill", am5.color(0x6e6e82));
      yRenderer.labels.template.setAll({
        fill: am5.color(0x6e6e82),
        fontSize: 12,
        paddingLeft: 12.5,
      });

      this.newCustomersSeries = chart.series.push(
        am5xy.LineSeries.new(this.root, {
          name: this.$t("dashboardPage.new"),
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "new_customer",
          valueXField: "day",
          stroke: am5.color(0x001991),
          fill: am5.color(0x001991),
          tooltip: am5.Tooltip.new(this.root, {
            labelText: `${this.$t("dashboardPage.new")}: {valueY}`,
          }),
        })
      );

      this.newCustomersSeries.strokes.template.setAll({
        strokeWidth: 2,
      });

      this.newCustomersSeries
        .get("tooltip")
        .get("background")
        .set("fillOpacity", 0.5);

      this.customersReturningSeries = chart.series.push(
        am5xy.LineSeries.new(this.root, {
          name: this.$t("dashboardPage.recurrent"),
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "return_customer",
          valueXField: "day",
          stroke: am5.color(0x001991),
          tooltip: am5.Tooltip.new(this.root, {
            labelText: `${this.$t("dashboardPage.recurrent")}: {valueY}`,
          }),
        })
      );
      this.customersReturningSeries.strokes.template.setAll({
        strokeDasharray: [2, 2],
        strokeWidth: 2,
      });

      this.customersReturningSeries.get("tooltip").get("background").setAll({
        fillOpacity: 0.5,
      });

      this.root.dateFormatter.setAll({
        dateFormat: "yyyy-MM-dd",
        dateFields: ["valueX"],
      });

      this.newCustomersSeries.data.processor = am5.DataProcessor.new(
        this.root,
        {
          dateFormat: "yyyy-MM-dd",
          dateFields: ["day"],
        }
      );

      this.customersReturningSeries.data.processor = am5.DataProcessor.new(
        this.root,
        {
          dateFormat: "yyyy-MM-dd",
          dateFields: ["day"],
        }
      );

      this.newCustomersSeries.appear(1000);
      this.customersReturningSeries.appear(1000);

      let legend = chart.children.push(
        am5.Legend.new(this.root, {
          centerY: am5.percent(20),
          y: am5.percent(100),
        })
      );

      legend.data.setAll(chart.series.values);

      this.setData();
      chart.appear(1000, 100);
    },
  },
  mounted() {
    this.draw();
  },
  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 350px;
}
</style>
