<template>
  <NbCardBox>
    <h4 class="heading-4">{{ $t("dashboardPage.topConsumers") }}</h4>

    <div class="table">
      <div class="d-flex justify-content-between">
        <span class="heading-1">{{ $tc("dashboardPage.consumers", 1) }}</span>
        <span class="heading-1">{{ $t("totalValue") }}</span>
      </div>
      <div class="content">
        <div
          v-for="consumer in topConsumersRanking"
          class="item"
          :key="consumer.ranking"
        >
          <div class="ranking">{{ consumer.ranking }}.</div>
          <div class="customer">
            <h1 class="heading-2 m-0">{{ consumer.text }}</h1>
            <small class="body-1">São Paulo, Brasil</small>
          </div>
          <div class="orders">
            <span class="heading-3">{{ consumer.val }}</span>
          </div>
        </div>
      </div>
    </div>
    <button
      v-show="topConsumers.length >= 6"
      class="btn p-0 link-1"
      @click="showMoreResult = !showMoreResult"
    >
      {{
        showMoreResult
          ? `${$t("dashboardPage.showLess")} >`
          : `${$t("dashboardPage.showMore")} >`
      }}
    </button>
  </NbCardBox>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";

export default {
  name: "TopConsumers",
  props: {
    topConsumers: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbCardBox,
  },
  data() {
    return {
      showMoreResult: false,
    };
  },
  computed: {
    topConsumersRanking() {
      const result = this.orderByValue();
      if (this.showMoreResult) {
        return result;
      }

      return result.slice(0, 5);
    },
  },
  methods: {
    orderByValue() {
      return this.topConsumers
        ?.sort((a, b) => b.val - a.val)
        .map((item, index) => ({
          ranking: index + 1,
          ...item,
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 1.5rem;
  max-height: 490px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    padding-right: 1rem;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-40);
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
.content {
  .item {
    background-color: var(--background);
    padding: 1rem;
    border-radius: 4px;
    display: flex;
    margin-top: 0.25rem;
    .ranking {
      font-weight: bold;
      margin-top: -2px;
    }
    .customer {
      flex: 1;
      margin-left: 0.5rem;
    }
    .orders {
      span {
        background-color: var(--gray-05);
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        vertical-align: top;
      }
    }

    & + .item {
      margin-top: 0.5rem;
    }
  }
}
</style>
