<template>
  <table>
    <thead>
      <th
        v-for="column in columns"
        :class="[
          'heading-1',
          { 'text-left': column?.align === 'left' },
          { 'text-right': column?.align === 'right' },
        ]"
        :key="column.field"
      >
        {{ column.name }}
      </th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <td v-for="column in columns" :key="column.field">
          <slot :name="column.field" :item="item">
            {{ item[column.field] }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TablePieInfo",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  th {
    color: var(--gray-60);
    padding-bottom: 0.5rem;
    position: sticky;
    top: 0;
    background-color: var(--white);
  }
  td {
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &:first-child {
      min-width: 132px;
    }
    &:last-child {
      text-align: right;
    }
  }

  tr {
    border-top: 1px solid var(--gray-10);
  }
}
</style>
