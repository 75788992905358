<template>
  <div class="box-info">
    <h2 class="heading-2">{{ title }}</h2>
    <h1>{{ value }}</h1>
  </div>
</template>

<script>
export default {
  name: "BoxChart",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.box-info {
  background-color: var(--background);
  padding: 1.5rem;
  border-radius: 4px;
  h1 {
    font-size: 2rem;
    font-weight: bold;
  }
}
</style>
