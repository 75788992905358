<template>
  <NbCardBox>
    <h4 class="heading-4">{{ $t("dashboardPage.volumesByRegion") }}</h4>

    <GeoDataChart :values="stateData" />

    <div class="table-container simple-scroll">
      <table>
        <thead>
          <th class="text-left heading-1">{{ $t("state") }}</th>
          <th></th>
          <th class="text-right heading-1">
            {{ $t("dashboardPage.volumes") }}
          </th>
        </thead>
        <tbody>
          <tr v-for="item in volumesRanking" :key="item.state">
            <td class="state">
              <strong class="heading-2">{{ item.state }}</strong>
            </td>
            <td class="progress-data">
              <b-popover
                :target="item.state"
                triggers="hover focus"
                variant="dark"
              >
                {{ item.percent }}%
              </b-popover>
              <div
                :id="item.state"
                class="bar"
                data-toggle="tooltip"
                :tooltip="item.percent + '%'"
              >
                <div
                  class="progress"
                  :style="{ width: item.percent + '%' }"
                ></div>
              </div>
            </td>
            <td>
              <strong class="heading-2">{{ item.volumes }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="btn p-0 link-1" @click="showMoreResult = !showMoreResult">
      {{
        showMoreResult
          ? `${$t("dashboardPage.showLess")} >`
          : `${$t("dashboardPage.showMore")} >`
      }}
    </button>
  </NbCardBox>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";
import GeoDataChart from "../GeoDataChart.vue";
import * as am5 from "@amcharts/amcharts5";
import { STATES } from "../../../constants";

export default {
  name: "OrdersByStates",
  components: {
    NbCardBox,
    GeoDataChart,
  },
  props: {
    stateInfo: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stateData: [],
      volumesPercent: [],
      showMoreResult: false,
    };
  },
  watch: {
    stateInfo(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.setStateData();
        this.setVolumesPercent();
      }
    },
  },
  computed: {
    volumesRanking() {
      if (this.showMoreResult) {
        return this.volumesPercent;
      }

      return this.volumesPercent.slice(0, 5);
    },
  },
  methods: {
    setStateData() {
      const result = this.stateInfo?.map((data) => ({
        id: `BR-${data.state}`,
        value: data.volumes,
        tooltipText: ``,
        settings: {
          fill: data.volumes > 0 ? am5.color(0xff0000) : am5.color(0xe1e1f5),
        },
      }));

      this.stateData = result;
    },
    setVolumesPercent() {
      const total = this.stateInfo.reduce(
        (acc, current) => acc + current.volumes,
        0
      );

      const states = Object.values(STATES.BR_STATES).map((state) => {
        const matchData = this.stateInfo.find((item) => item.state === state);
        const volumes = matchData?.volumes || 0;

        return {
          state,
          volumes,
          percent: (volumes / total) * 100,
        };
      });

      const result = states.sort((a, b) => b.percent - a.percent);

      this.volumesPercent = result;
    },
  },
  mounted() {
    this.setStateData();
    this.setVolumesPercent();
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  th {
    color: var(--gray-60);
    padding-bottom: 0.5rem;
    position: sticky;
    top: 0;
    background-color: var(--white);
  }
  td {
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
    &:first-child {
      min-width: 132px;
    }
    &:last-child {
      text-align: right;
    }
  }

  tr {
    border-top: 1px solid var(--gray-10);
  }

  .state {
    strong {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      max-width: 120px;
    }
  }
  .progress-data {
    width: 100%;
  }
  .bar {
    background-color: var(--gray-20);
    border-radius: 10px;
    height: 8px;
    overflow: hidden;
    .progress {
      height: 100%;
      background-color: var(--primary);
    }
  }
}

.table-container {
  height: 18.5rem;
  overflow-y: auto;
  padding-right: 0.5rem;
}
</style>
