<template>
  <BaseTemplate>
    <NbPageTitle
      :title="$t('dashboardPage.title')"
      :subTitle="$t('dashboardPage.subtitle')"
    />

    <div class="content mx-3 mb-4">
      <NbTabs class="tabs" :tabs="tabsOptions" @switchTab="switchTab">
        <template #right>
          <div class="pb-2">
            <strong>{{ $t("dashboardPage.period") }}:</strong>
            <div class="d-flex align-items-center">
              <DatePicker v-model="rangeDate" range />
              <NbButton
                @click="setFilters()"
                class="filter ml-2"
                variant="quaternary"
                :disabled="loading"
              >
                <NbSpinner v-if="loading" align="center" size="2.1rem" />
                <NbIcon
                  v-else
                  icon="search"
                  :attributes="{
                    width: '1rem',
                    height: '1rem',
                  }"
                />
              </NbButton>
            </div>
          </div>
        </template>
      </NbTabs>

      <NbSpinner v-if="loading" align="center" size="2.1rem" />
      <template v-if="overview">
        <transition-group name="tabs" tag="div">
          <OverviewTab
            v-show="currentTab === tabs.overview"
            :start-date="filters.start_date"
            :end-date="filters.end_date"
            :overview="overview"
            key="overview"
          />

          <SalesTrendTab
            v-show="currentTab === tabs.salesTrend"
            :sales-trend="overview.sales_trend"
            key="sales_trend"
          />

          <ConsumersTab
            v-show="currentTab === tabs.consumers"
            :customers-overview="overview.customer_overview"
            :state-info="overview.state_info"
            :start-date="filters.start_date"
            :end-date="filters.end_date"
            key="consumers"
          />
        </transition-group>
      </template>
    </div>
  </BaseTemplate>
</template>

<script>
import NbPageTitle from "../../components/pagescomponents/NbPageTitle.vue";
import BaseTemplate from "../BaseTemplate";
import NbTabs from "@/components/tables/NbTabs.vue";
import ConsumersTab from "./components/consumers/ConsumersTab.vue";
import SellerService from "../../services/SellerService";
import OverviewTab from "./components/overview/OverviewTab.vue";
import NbSpinner from "../../components/loadings/NbSpinner.vue";
import SalesTrendTab from "./components/salesTrend/SalesTrendTab.vue";
import DatePicker from "../../components/generic/DatePicker.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import moment from "moment";
import NbIcon from "@/components/icons/NbIcon.vue";
const sellerService = new SellerService();

export default {
  name: "Dashboard",
  components: {
    BaseTemplate,
    NbPageTitle,
    NbTabs,
    ConsumersTab,
    OverviewTab,
    NbSpinner,
    SalesTrendTab,
    DatePicker,
    NbButton,
    NbIcon,
  },
  data() {
    return {
      currentTab: "",
      tabs: {
        overview: "overview",
        salesTrend: "salesTrend",
        consumers: "consumers",
      },
      loading: false,
      sellertId: null,
      overview: null,
      filters: {
        start_date: null,
        end_date: null,
      },
      rangeDate: [null, null],
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.tabs.overview,
          label: this.$t("dashboardPage.overview"),
          current: this.currentTab,
        },
        {
          id: this.tabs.salesTrend,
          label: this.$t("dashboardPage.salesTrend"),
          current: this.currentTab,
        },
        {
          id: this.tabs.consumers,
          label: this.$tc("dashboardPage.consumers", 2),
          current: this.currentTab,
        },
      ];
    },
  },
  methods: {
    switchTab(tab) {
      this.currentTab = tab;
    },
    async getCurrentSeller() {
      const { data } = await sellerService.getCurrentSeller();
      this.sellerId = data.data.id;
      this.getAnalyticDatas();
    },
    setFilters() {
      const [start, end] = this.rangeDate;
      this.filters = {
        start_date: start ? moment(start).format("YYYY-MM-DD") : null,
        end_date: end ? moment(end).format("YYYY-MM-DD") : null,
      };

      this.getAnalyticDatas();
    },
    async getAnalyticDatas() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await sellerService.getAnalyticDatas(this.filters, this.sellerId);
        this.overview = data;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.rangeDate = [
      moment().subtract(30, "days").toDate(),
      moment().toDate(),
    ];
    this.setFilters();
    this.currentTab = this.tabs.overview;
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  margin-bottom: 1.5rem;
}

.filter {
  height: 50px;
}

.tabs-enter-active,
.tabs-leave-active {
  transition: all 0.5s;
}
.tabs-enter,
.tabs-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
