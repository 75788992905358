<template>
  <div class="item">
    <div class="ranking">{{ ranking }}.</div>
    <div class="reference">
      <h1 class="heading-2 m-0">{{ title }}</h1>
      <small v-show="description" class="body-1">{{ description }}</small>
    </div>
    <div class="value">
      <span class="heading-3">{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RankingCard",
  props: {
    ranking: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  background-color: var(--background);
  padding: 1.5rem 1rem;
  border-radius: 4px;
  display: flex;
  margin-top: 0.25rem;
  .ranking {
    font-weight: bold;
    margin-top: -2px;
  }
  .reference {
    flex: 1;
    margin-left: 0.5rem;
  }
  .value {
    span {
      background-color: var(--gray-05);
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      vertical-align: top;
    }
  }
}
</style>
