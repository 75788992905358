<template>
  <div ref="chartdiv" style="width: 100%; height: 200px"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import chartLocaleMixin from "../../../../mixins/chart-locale-mixin";

export default {
  name: "PieCategoryChart",
  mixins: [chartLocaleMixin],
  props: {
    values: {
      type: Array,
      required: true,
    },
    valueField: {
      type: String,
      default: "quantity",
    },
    categoryField: {
      type: String,
      default: "category",
    },
  },
  data() {
    return {
      /** @type {am5.Root} */
      root: null,
      /** @type {am5percent.PieSeries} */
      series: null,
    };
  },
  watch: {
    values() {
      this.setData();
    },
    currentLocale(old, value) {
      if (old !== value) {
        this.root.dispose();
        this.draw();
      }
    },
  },
  methods: {
    setData() {
      this.series.data.setAll(this.values);
    },
    draw() {
      this.root = am5.Root.new(this.$refs.chartdiv);
      this.root._logo?.dispose();

      this.root.setThemes([am5themes_Animated.new(this.root)]);
      let chart = this.root.container.children.push(
        am5percent.PieChart.new(this.root, {
          layout: this.root.verticalLayout,
          innerRadius: am5.percent(50),
          radius: am5.percent(75),
        })
      );

      this.series = chart.series.push(
        am5percent.PieSeries.new(this.root, {
          valueField: this.valueField,
          categoryField: this.categoryField,
          alignLabels: false,
        })
      );

      this.series.labels.template.setAll({
        // textType: "circular",
        centerX: 0,
        centerY: 0,
        text: "[bold fontSize:14px]{valuePercentTotal.formatNumber('0.00')}%",
      });

      this.series.slices.template.setAll({
        stroke: am5.color(0xffffff),
        strokeWidth: 6,
      });

      this.series.slices.template.adapters.add("fill", function (fill, target) {
        const item = target.dataItem.dataContext;
        if (item?.color) {
          return am5.color(item.color);
        }

        return fill;
      });

      const label = this.series.children.push(
        am5.Label.new(this.root, {
          textAlign: "center",
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
          text: "[bold fontSize:16px]{valueSum}[/]\n[fontSize:12px #6E6E82]Pedidos[/]",
          oversizedBehavior: "fit",
        })
      );

      this.series.onPrivate("valueSum", function () {
        label.text.markDirtyText();
      });

      this.setData();

      this.series.appear(1000, 100);
    },
  },
  mounted() {
    this.draw();
  },
};
</script>

<style lang="scss" scoped></style>
