<template>
  <NbCardBox>
    <h4 class="heading-4 m-0">
      {{ $tc("dashboardPage.consumers", 2) }} ({{ $t("dashboardPage.new") }} x
      {{ $t("dashboardPage.recurrent") }})
    </h4>

    <div class="metrics my-4">
      <div>
        <h4 class="heading-4 m-0">
          {{
            $t("dashboardPage.totalNewCustomers", {
              count: totalNewCustomers,
            })
          }}
        </h4>
      </div>
      <div>
        <h4 class="heading-4 m-0">
          {{
            $t("dashboardPage.totalCustomersReturned", {
              count: totalReturnCustomers,
            })
          }}
        </h4>
      </div>
    </div>

    <ConsumersChart :values="customersChartData" />
  </NbCardBox>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";
import ConsumersChart from "./ConsumersChart.vue";
import moment from "moment";

export default {
  name: "ConsumersNewAndRecurrent",
  props: {
    customersInfo: {
      type: Array,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  components: {
    NbCardBox,
    ConsumersChart,
  },
  computed: {
    totalNewCustomers() {
      return this.customersInfo?.reduce((acc, current) => {
        return acc + current.new_customer;
      }, 0);
    },
    totalReturnCustomers() {
      return this.customersInfo?.reduce((acc, current) => {
        return acc + current.return_customer;
      }, 0);
    },
    customersChartData() {
      const result = [];
      if (this.customersInfo?.length) {
        const customersInfo = this.customersInfo;
        const start = moment(this.startDate);
        const end = moment(this.endDate);
        const diffInDays = end.diff(start, "days");

        let currentDate = start;
        for (let i = 0; i <= diffInDays; i++) {
          const formattedDate = currentDate.format("YYYY-MM-DD");
          const matchData = customersInfo.find(
            (item) => item.day === formattedDate
          );

          result.push({
            day: formattedDate,
            new_customer: matchData?.new_customer || 0,
            return_customer: matchData?.return_customer || 0,
          });
          currentDate = currentDate.add(1, "day");
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.consumers {
  .metrics {
    display: grid;
    gap: 1rem;
    > div {
      background-color: var(--background);
      padding: 0.5rem 1rem;
      border-radius: 4px;
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
