<template>
  <NbCardBox style="height: 532px">
    <h4 class="heading-4 mb-4">{{ $t("dashboardPage.salesByChannel") }}</h4>

    <PieCategoryChart :values="ranking" category-field="channel" />

    <div class="ranking simple-scroll">
      <TablePieInfo :columns="tableColumns" :data="tableData">
        <template #channel="{ item }">
          <div class="category d-flex align-items-center">
            <span
              class="color ml-2"
              :style="{ 'background-color': item.color }"
            ></span>
            <div class="text">
              <span class="body-2 mx-2">{{ item.channel }}</span>
            </div>
            <span class="percent">({{ item.percent }}%)</span>
          </div>
        </template>
        <template #quantity="{ item }">
          <div class="quantity">
            {{ item.quantity }}
          </div>
        </template>
      </TablePieInfo>
    </div>
    <button
      v-show="ranking.length > 5"
      class="btn p-0 link-1"
      @click="showMoreResult = !showMoreResult"
    >
      {{
        showMoreResult
          ? `${$t("dashboardPage.showLess")} >`
          : `${$t("dashboardPage.showMore")} >`
      }}
    </button>
  </NbCardBox>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";
import PieCategoryChart from "./PieCategoryChart.vue";
import TablePieInfo from "../TablePieInfo.vue";

export default {
  name: "SalesChannel",
  props: {
    salesChannel: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbCardBox,
    PieCategoryChart,
    TablePieInfo,
  },
  data() {
    return {
      showMoreResult: false,
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          name: this.$t("dashboardPage.ecommerce"),
          field: "channel",
          align: "left",
        },
        { name: this.$t("orders"), field: "quantity", align: "right" },
      ];
    },
    ranking() {
      const total = this.salesChannel?.reduce(
        (acc, current) => acc + current.quantity,
        0
      );
      const result = this.sortable(this.salesChannel);

      return result.map((item, index) => ({
        ...item,
        ranking: index + 1,
        percent: ((item.quantity / total) * 100).toFixed(2),
        color: this.getColor(index),
      }));
    },
    tableData() {
      if (this.showMoreResult) {
        return this.ranking;
      }

      return this.ranking.slice(0, 5);
    },
  },
  methods: {
    getColor(index) {
      const defaultColors = [
        "#001991",
        "#3347a7",
        "#6675bd",
        "#99a3d3",
        "#ccd1e9",
      ];

      const randomColor = () => {
        const result = (Math.random() * 0x001991 * 1000000).toString(16);
        return "#" + result.slice(0, 6);
      };

      return defaultColors[index] || randomColor();
    },
    sortable(data) {
      return data?.sort((a, b) => b.quantity - a.quantity) || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  font-size: 0.75rem;
  .color {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    display: inline-block;
  }
  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
  }
}
.quantity {
  color: var(--gray-60);
  font-size: 0.75rem;
  font-weight: bold;
}

.ranking {
  height: 13rem;
  overflow-y: auto;
}
</style>
