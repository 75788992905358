<template>
  <NbCardBox>
    <h4 class="heading-4 mb-4">{{ $t("dashboardPage.totalOrders") }}</h4>

    <div class="box mb-4">{{ total }} {{ $t("orders") }}</div>

    <div ref="chart" class="chart"></div>
  </NbCardBox>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import chartLocaleMixin from "../../../../mixins/chart-locale-mixin";

export default {
  name: "TotalOrdersSold",
  mixins: [chartLocaleMixin],
  props: {
    solds: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbCardBox,
  },
  data() {
    return {
      /** @type {am5.Root} */
      root: null,
      /** @type {am5xy.ColumnSeries} */
      series: null,
    };
  },
  computed: {
    total() {
      return this?.solds?.reduce((acc, current) => acc + current.quantity, 0);
    },
  },
  watch: {
    currentLocale(old, value) {
      if (old !== value) {
        this.root.dispose();
        this.draw();
      }
    },
    solds() {
      this.setData();
    },
  },
  methods: {
    setData() {
      this.series?.data?.setAll(this.solds);
    },
    draw() {
      this.root = am5.Root.new(this.$refs.chart);
      this.root._logo?.dispose();

      this.root.setThemes([
        am5themes_Animated.new(this.root),
        am5themes_Responsive.new(this.root),
      ]);

      const chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
          paddingLeft: 0,
          paddingRight: 0,
        })
      );

      chart.get("colors").set("step", 3);

      const cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
      cursor.lineY.set("visible", false);

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(this.root, {
          maxDeviation: 0.3,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(this.root, {
            minorGridEnabled: true,
          }),
          tooltip: am5.Tooltip.new(this.root, {}),
        })
      );

      const xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);
      xRenderer.labels.template.setAll({
        fill: am5.color(0x6e6e82),
        fontSize: 12,
        paddingTop: 12.5,
        paddingBottom: 24,
      });

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          maxDeviation: 0.3,
          maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(this.root, {
            opposite: true,
          }),
        })
      );

      const yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.set("fill", am5.color(0x6e6e82));
      yRenderer.labels.template.setAll({
        fill: am5.color(0x6e6e82),
        fontSize: 12,
        paddingLeft: 12.5,
      });

      this.series = chart.series.push(
        am5xy.LineSeries.new(this.root, {
          name: this.$t("dashboardPage.currentPeriod"),
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "quantity",
          valueXField: "date",
          stroke: am5.color(0x001991),
          fill: am5.color(0x001991),
          tooltip: am5.Tooltip.new(this.root, {
            labelText: `${this.$t("quantity")}: {valueY}`,
          }),
        })
      );

      this.series.strokes.template.setAll({
        strokeWidth: 2,
      });

      this.series.get("tooltip").get("background").set("fillOpacity", 0.5);

      this.root.dateFormatter.setAll({
        dateFormat: "yyyy-MM-dd",
        dateFields: ["valueX"],
      });

      this.series.data.processor = am5.DataProcessor.new(this.root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"],
      });

      const legend = chart.children.push(
        am5.Legend.new(this.root, {
          centerY: am5.percent(20),
          y: am5.percent(100),
        })
      );

      legend.data.setAll(chart.series.values);

      this.series.appear(1000);

      this.setData();
      chart.appear(1000, 100);
    },
  },
  mounted() {
    this.draw();
  },
};
</script>

<style lang="scss" scoped>
.box {
  background-color: var(--background);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.chart {
  height: 230px;
  width: 100%;
}
</style>
