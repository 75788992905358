<template>
  <div class="consumers">
    <ConsumersNewAndRecurrent
      class="first"
      :customers-info="customersOverview.customers_info"
      :startDate="startDate"
      :endDate="endDate"
    />
    <TopConsumers
      class="top-consumers"
      :top-consumers="customersOverview.top_costumers"
    />
    <OrdersByStates :state-info="stateInfo" />
  </div>
</template>

<script>
import TopConsumers from "./TopConsumers.vue";
import ConsumersNewAndRecurrent from "./ConsumersNewAndRecurrent.vue.vue";
import OrdersByStates from "../OrdersByStates.vue";
export default {
  name: "ConsumersTab",
  props: {
    customersOverview: {
      type: Object,
      required: true,
    },
    stateInfo: {
      type: Array,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  components: {
    TopConsumers,
    ConsumersNewAndRecurrent,
    OrdersByStates,
  },
};
</script>

<style lang="scss" scoped>
.consumers {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .first {
      grid-column: span 2 / span 2;
    }
  }

  .top-consumers {
    height: fit-content;
  }
}
</style>
