<template>
  <NbCardBox class="d-flex flex-column">
    <h4 class="heading-4">{{ $t("dashboardPage.ordersByStatus") }}</h4>

    <div class="boxes">
      <div v-for="status in statuses" :key="status.id" class="box">
        <h2 class="heading-2">{{ status.title }}</h2>
        <div class="bar">
          <div class="progress" :style="{ width: status.percent + '%' }"></div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <p class="body-3 m-0">
            {{ status.volumes }} volumes ({{ status.percent }}%)
          </p>
          <router-link to="orders" class="link-1"
            >{{ $t("dashboardPage.seeOrders") }} ></router-link
          >
        </div>
      </div>
    </div>

    <router-link class="link-1" to="/orders"
      >{{ $t("dashboardPage.seeTableWithAllOrders") }} ></router-link
    >
  </NbCardBox>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";
import { ORDER_STATUS } from "../../../../constants";

export default {
  name: "OrdersByStatus",
  props: {
    statusInfo: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbCardBox,
  },
  computed: {
    statuses() {
      const volumesTotal = this.statusInfo?.reduce(
        (acc, current) => acc + current.volumes,
        0
      );

      const dataStatus = {
        [ORDER_STATUS.NEW]: this.$t("orderStatus.new"),
        [ORDER_STATUS.READY_TO_SHIP]: this.$t("orderStatus.readyToShip"),
        [ORDER_STATUS.PICKUP]: this.$t("orderStatus.pickUp"),
        [ORDER_STATUS.SHIPPED]: this.$t("orderStatus.shipped"),
        [ORDER_STATUS.IN_TRANSIT]: this.$t("orderStatus.inTransit"),
        [ORDER_STATUS.FAILED_ATTEMP]: this.$t("orderStatus.failedAttempt"),
        [ORDER_STATUS.OUT_FOR_DELIVERY]: this.$t("orderStatus.outForDelivery"),
        [ORDER_STATUS.DELIVERED]: this.$t("orderStatus.delivered"),
        [ORDER_STATUS.DELIVERED_FAILED]: this.$t("orderStatus.deliveryFailed"),
        [ORDER_STATUS.PARTIAL_DELIVERY]: this.$t("orderStatus.partialDelivery"),
        [ORDER_STATUS.PARTIAL_DELIVERY_FAILED]: this.$t(
          "orderStatus.partialDeliveryFailed"
        ),
      };

      const statusKeys = Object.keys(dataStatus).map(Number);
      return this.statusInfo
        ?.filter((item) => statusKeys.includes(item.tracking_code))
        ?.map((item) => ({
          id: item.trackin_code,
          title: dataStatus[item.tracking_code],
          volumes: item.volumes,
          percent: Math.floor((item.volumes / volumesTotal) * 100),
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
.boxes {
  flex: 1;
  .box {
    background-color: var(--background);
    border-radius: 4px;
    padding: 1rem 1.5rem;
    & + .box {
      margin-top: 0.5rem;
    }

    .bar {
      height: 8px;
      border-radius: 10px;
      overflow: hidden;
      background-color: var(--gray-20);
      margin-top: 0.75rem;
      margin-bottom: 0.5rem;
      .progress {
        height: 100%;
        background-color: var(--primary);
      }
    }
  }
}
</style>
