<template>
  <NbCardBox>
    <h4 class="heading-4 mb-4">{{ $t("dashboardPage.bestSellers") }}</h4>

    <div>
      <div class="d-flex justify-content-between">
        <span class="heading-1">{{ $t("product") }}</span>
        <span class="heading-1">$ {{ $t("value") }}</span>
      </div>
      <div class="ranking simple-scroll">
        <RankingCard
          v-for="product in rankingProducts"
          :key="product.ranking"
          :ranking="product.ranking"
          :title="product.title"
          :description="product.category"
          :value="product.total"
          class="item"
        />
      </div>
      <button
        v-show="products.length > 5"
        class="btn p-0 link-1"
        @click="showMoreResult = !showMoreResult"
      >
        {{
          showMoreResult
            ? `${$t("dashboardPage.showLess")} >`
            : `${$t("dashboardPage.showMore")} >`
        }}
      </button>
    </div>
  </NbCardBox>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";
import chartLocaleMixin from "../../../../mixins/chart-locale-mixin";
import RankingCard from "../../../../components/chart/RankingCard.vue";

export default {
  name: "TopProducts",
  mixins: [chartLocaleMixin],
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbCardBox,
    RankingCard,
  },
  data() {
    return {
      showMoreResult: false,
    };
  },
  computed: {
    rankingProducts() {
      const topProducts = this.products;
      const ranking = topProducts
        ?.sort((a, b) => b.quantity - a.quantity)
        ?.map((product, index) => ({
          ranking: index + 1,
          title: product.text,
          total: this.formatNumber(product.val),
          category: "",
        }));

      if (this.showMoreResult) {
        return ranking;
      }

      return ranking.slice(0, 5);
    },
  },
  methods: {
    formatNumber(val) {
      return parseFloat(val).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.ranking {
  height: 24.68rem;
  overflow-y: auto;
  .item + .item {
    margin-top: 0.5rem;
  }
}

.simple-scroll {
  transition: all 0.3s;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    padding-right: 0;
  }

  &::-webkit-scrollbar-thumb {
    opacity: 0;
    visibility: hidden;
    background: var(--gray-40);
    border-radius: 0.5rem;
    transition: all 0.3s;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      opacity: 1;
      visibility: visible;
      background: var(--gray-40);
      border-radius: 0.5rem;
      transition: all 0.3s;
    }
  }
}
</style>
