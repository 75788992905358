<template>
  <div class="sales-trend">
    <div class="lines">
      <TotalOrdersSold :solds="salesTrend.solds" />
      <TotalSales :sales="salesTrend.sales" />
    </div>
    <div class="area">
      <TopProducts :products="salesTrend.top_products" />
      <OrdersByCategory :sales-category="salesTrend.sales_category" />
      <SalesChannel :sales-channel="salesTrend.sales_channel" />
    </div>
  </div>
</template>

<script>
import TotalOrdersSold from "./TotalOrdersSold.vue";
import TotalSales from "./TotalSales.vue";
import TopProducts from "./TopProducts.vue";
import OrdersByCategory from "./OrdersByCategory.vue";
import SalesChannel from "./SalesChannel.vue";

export default {
  name: "SalesTrendTab",
  props: {
    salesTrend: {
      type: Object,
      required: true,
    },
  },
  components: {
    TotalOrdersSold,
    TotalSales,
    TopProducts,
    OrdersByCategory,
    SalesChannel,
  },
};
</script>

<style lang="scss" scoped>
.sales-trend,
.lines,
.area {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media screen and (min-width: 768px) {
  .lines,
  .area {
    display: grid;

    gap: 1.5rem;
  }
  .lines {
    grid-template-columns: repeat(2, 1fr);
  }

  .area {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
