<template>
  <div ref="chartdiv" class="chart"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_brazilLow from "@amcharts/amcharts5-geodata/brazilLow";
import chartLocaleMixin from "../../mixins/chart-locale-mixin";

export default {
  name: "GeoData",
  mixins: [chartLocaleMixin],
  props: {
    values: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      /** @type {am5map.MapPolygonSeries} */
      polygonSeries: null,
    };
  },
  watch: {
    values(old, value) {
      if (old !== value) {
        this.setData();
      }
    },
    currentLocale(old, value) {
      if (old !== value) {
        this.root.dispose();
        this.draw();
      }
    },
  },
  methods: {
    setData() {
      this.polygonSeries.data.setAll(this.values);
    },
    draw() {
      const root = am5.Root.new(this.$refs.chartdiv);
      root._logo.dispose();

      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: "rotateX",
          panY: "none",
          layout: root.horizontalLayout,
        })
      );

      this.polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_brazilLow,
          fill: am5.color(0xe1e1f5),
          templateField: "settings",
        })
      );

      this.polygonSeries.mapPolygons.template.setAll({
        tooltipText: "{name}\nnenhum pedido",
        strokeWidth: 2,
        templateField: "settings",
      });

      this.polygonSeries.mapPolygons.template.states.create("hover", {
        fill: am5.color(0x001991),
      });

      this.polygonSeries.mapPolygons.template.adapters.add(
        "tooltipText",
        function (text, target) {
          const data = target.dataItem.dataContext;
          if (data?.value > 0) {
            return `${data?.name}\n${data?.value} pedidos`;
          }

          return text;
        }
      );

      this.polygonSeries.mapPolygons.template.adapters.add(
        "fill",
        function (fill, target) {
          if (target.dataItem && target.dataItem.dataContext?.value > 0) {
            const color = am5.color(0x6675bd);
            return color;
          }
          return fill;
        }
      );

      this.setData();
    },
  },
  mounted() {
    this.draw();
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 14.18rem;
}
</style>
