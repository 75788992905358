<template>
  <div class="overview">
    <OrderOverview
      class="first"
      :freight-value="overview.freight_value"
      :freight-cost="overview.freight_cost"
      :average-order-cost="overview.average_order_cost"
      :rejected-orders="overview.rejected_orders"
      :orders="overview.cost_volumes_info"
      :start-date="startDate"
      :end-date="endDate"
    />

    <OrdersByStatus :status-info="overview.status_info" />

    <OrdersByStates :state-info="overview.state_info" />
  </div>
</template>

<script>
import OrdersByStates from "../OrdersByStates.vue";
import OrderOverview from "./OrderOverview.vue";
import OrdersByStatus from "./OrdersByStatus.vue";

export default {
  name: "OverviewTab",
  props: {
    overview: {
      type: Object,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  components: { OrderOverview, OrdersByStatus, OrdersByStates },
};
</script>

<style lang="scss" scoped>
.overview {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .first {
      grid-column: span 2 / span 2;
    }
  }
}
</style>
