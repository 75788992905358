<template>
  <NbCardBox>
    <h4 class="heading-4">{{ $t("dashboardPage.ordersCreated") }}</h4>

    <div class="boxes">
      <BoxChart
        v-for="item in boxDetails"
        :key="item.title"
        :title="item.title"
        :value="item.value"
      />
    </div>
    <TotalOrdersChart :values="chartData" style="height: 350px" />
  </NbCardBox>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";
import BoxChart from "./BoxChart.vue";
import TotalOrdersChart from "../../../home/components/TotalOrdersChart.vue";

export default {
  name: "OrderOverview",
  components: {
    NbCardBox,
    BoxChart,
    TotalOrdersChart,
  },
  props: {
    freightValue: {
      type: Number,
      default: 0,
    },
    freightCost: {
      type: Number,
      default: 0,
    },
    averageOrderCost: {
      type: Number,
      default: 0,
    },
    rejectedOrders: {
      type: Number,
      default: 0,
    },
    orders: {
      type: Array,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    boxDetails() {
      const formatter = Intl.NumberFormat("pt-BR", {
        currency: "BRL",
        style: "currency",
      });
      return [
        {
          title: this.$t("dashboardPage.cards.averageOrderCost"),
          value: formatter.format(this.averageOrderCost),
        },
        {
          title: this.$t("dashboardPage.cards.rejectOrder"),
          value: this.rejectedOrders,
        },
        {
          title: this.$t("dashboardPage.cards.freightCost"),
          value: formatter.format(this.freightCost),
        },
        {
          title: this.$t("dashboardPage.cards.freightValue"),
          value: formatter.format(this.freightValue),
        },
      ];
    },
    chartData() {
      return this.orders.map((item) => ({
        date: item.day,
        value: item.orders,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.boxes {
  display: grid;
  gap: 1rem;
  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
